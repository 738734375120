<!-- Navbar -->
<nav class="main-header-siadci">
  <!-- Left navbar links -->
  <ul class="navbar-nav-siadci">
    <li class="nav-item">
      <!--<h3 class="py-2"><a href="" data-target="#sidebar" data-toggle="collapse" class="d-md-none"><i style="color: #b19834;" class="fa fa-bars fa-2x"></i></a> </h3>-->
      <h3 class="py-2"><a href=""  (click)="toggleMenuSidebar.emit()" data-target="#sidebar" data-toggle="collapse" class=""><i style="color:#494339;" class="fa fa-bars fa-2x"></i></a> </h3>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
     <!-- <a [routerLink]="['/']" class="nav-link">Home</a> -->
     <img src="../../../assets/img/2024-30 veracruz.png" alt="" class="logo-veracruz" >
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <img src="../../../assets/img/Amor_Ver.png" alt="" class="logo-veracruz2"  >
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a href="/">
        <img class="logo-main-siadci" src="../../../assets/img/siadci.jpg" alt="">
      </a>
    </li>
  </ul>



  <!-- Right navbar links -->
  <ul class="navbar-nav-siadci ml-auto">
    <!-- Messages Dropdown Menu -->
   <!-- <app-messages-dropdown-menu></app-messages-dropdown-menu> -->
    <!-- Notifications Dropdown Menu -->
    <app-notifications-dropdown-menu></app-notifications-dropdown-menu>
    <app-notifications-urgent-dropdown-menu></app-notifications-urgent-dropdown-menu>
    <app-login-dropdown></app-login-dropdown>

  </ul>
</nav>
<!-- /.navbar -->
<div class="siadci-footer-head"></div>