<section class="content-header header-siadci">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <h1>Listado Respuestas Recibidas</h1>
        </div>
        <div class="col-sm-6">
          <h3>Muestra los registros de Respuestas Recibidas</h3>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
    
    <section class="content content-sidci">
      <!-- Default box -->
      <div class="container-fluid">
        <div class="card-siadci py-4">
          <div
            style="
              width: 95%;
              overflow: auto;
              margin-left: auto;
              margin-right: auto;
            "
          >
            <div class="table-siadci" [style.width]="Ancho" >
              <p-table
                #dt
                class="ng2-smart-table"
                [resizableColumns]="true" styleClass="p-datatable-gridlines" [autoLayout]="true"
                [columns]="cols"
                [value]="data"
                paginator="true"
                [rows]="5"
                [style]="{width:'1500px'}"
              >
                <ng-template pTemplate="caption">
                  <!-- <div style="text-align: left">
                    <i class="fa fa-search" style="margin: 4px 4px 0 0"></i>
                    <input
                      type="text"
                      pInputText
                      size="100"
                      placeholder="Filtro global"
                      (input)="dt.filterGlobal($event.target.value, 'contains')"
                      style="width: auto"
                    />
                  </div> -->
                  <div class="search-wrapper-siadci d-flex">
                    <input
                      type="text"
                      pInputText
                      placeholder="Filtro global"
                      class="search-input"
                      (input)="dt.filterGlobal($event.target.value, 'contains')"
                    />
                    <i class="fa fa-search"></i>
                  </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns" [ngStyle]="{'width': col.width,'text-align': 'center' }">
                      <div>
                        {{ col.header }}
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field">
                      <p-sortIcon
                        [field]="col.field"
                        [pSortableColumn]="col.field"
                      ></p-sortIcon>
    
                      <input
                        *ngSwitchCase="'fechaTurno'"
                        pInputText
                        type="text"
                        placeholder="Contiene"
                        class="ui-column-filter"
                        style="width: 100%"
                        (input)="
                          dt.filter($event.target.value, col.field, 'contains')
                        "
                      />
    
                      <input
                        *ngSwitchCase="'cadenaFolio'"
                        pInputText
                        type="text"
                        placeholder="Contiene"
                        class="ui-column-filter"
                        style="width: 100%"
                        (input)="
                          dt.filter($event.target.value, col.field, 'contains')
                        "
                      />
    
                      <input
                        *ngSwitchCase="'numeroDocumentoOriginal'"
                        pInputText
                        type="text"
                        placeholder="Contiene"
                        class="ui-column-filter"
                        style="width: 100%"
                        (input)="
                          dt.filter($event.target.value, col.field, 'contains')
                        "
                      />
    
                      <input
                        *ngSwitchCase="'OrigenNombre'"
                        pInputText
                        type="text"
                        placeholder="Contiene"
                        class="ui-column-filter"
                        style="width: 100%"
                        (input)="
                          dt.filter($event.target.value, col.field, 'contains')
                        "
                      />
    
                      <input
                        *ngSwitchCase="'DestinoNombre'"
                        pInputText
                        type="text"
                        placeholder="Contiene"
                        class="ui-column-filter"
                        style="width: 100%"
                        (input)="
                          dt.filter($event.target.value, col.field, 'contains')
                        "
                      />
    
                     
    
                      <input
                        *ngSwitchCase="'agrupador'"
                        pInputText
                        type="text"
                        placeholder="Contiene"
                        class="ui-column-filter"
                        style="width: 100%"
                        (input)="
                          dt.filter($event.target.value, col.field, 'contains')
                        "
                      />
  
                          <input
                        *ngSwitchCase="'documentoAsunto'"
                        pInputText
                        type="text"
                        placeholder="Contiene"
                        class="ui-column-filter"
                        style="width: 100%"
                        (input)="
                          dt.filter($event.target.value, col.field, 'contains')
                        "
                      />
  
                          <input
                        *ngSwitchCase="'estatus'"
                        pInputText
                        type="text"
                        placeholder="Contiene"
                        class="ui-column-filter"
                        style="width: 100%"
                        (input)="
                          dt.filter($event.target.value, col.field, 'contains')
                        "
                      />
  
                      
                    
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns">
                      <div
                        style="display: flex; white-space: normal; overflow: hidden"
                      >
                        <ng-container *ngIf="col.field == 'Acciones'">
                          <div class="" style="text-align: center;">
                            <button
                              pButton
                              type="button"
                              label="Visor"
                              class="btn-in-table main-color"
                              (click)="visorDocumentos(rowData)"
                            ></button>

                            <button
                              pButton
                              type="button"
                              label="Visor adjuntos"
                              class="btn-in-table main-color"
                              (click)="visorAdjuntosR(rowData)"
                            ></button>
  
                            <button
                              *ngIf="rowData.estatus == 'Turnado' || rowData.estatus == 'Returnado' "
                              pButton
                              type="button"
                              [routerLink]="[
                                '/turnos/formularioRER/' + rowData.trazaInternaId
                              ]"
                              label="Responder turno"
                              class="btn-in-table outline-color"
                            ></button>
                    
                            <ng-container *ngIf="apiService.BuscarPermiso('/webresources/trazainterna','post')"> 
                                  <button
                                  *ngIf="rowData.estatus == 'Respuesta' "
                                  pButton
                                  type="button"
                                  [routerLink]="[
                                    '/turnos/formularioRV/' + rowData.trazaInternaId
                                  ]"
                                  label="Reenviar respuesta"
                                  class="btn-in-table outline-color"
                                ></button>
                            </ng-container>
            
                                  <button
                                    type="button"
                                    *ngIf="rowData.estatus == 'Respuesta'"
                                    (click)="Mensaje(rowData)"
                                    pButton
                                    icon="pi pi-external-link"
                                    label="Mensaje"
                                    class="btn-in-table outline-color"
                                  ></button>
                                  
                                  <p-dialog
                                    header="Mensaje"
                                    [(visible)]="mensaje"
                                    [modal]="true"
                                    [style]="{ width: '50vw' }"
                                    [maximizable]="true"
                                    [baseZIndex]="10000"
                                    [draggable]="false"
                                    [resizable]="false"
                                  >
                                    {{ mens }}
            
                                    <!--<p-footer>
                                      <button type="button"  (click)="Instrucciones(rowData)" pButton icon="pi pi-external-link"
                                      label="Instrucciones"></button>
                                      <p-dialog header="Instrucciones" [(visible)]="instruccion" [modal]="true" [style]="{width: '50vw'}"
                                      [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
                                      {{ins}}
            
                                    </p-dialog>
                                    </p-footer>-->
                                  </p-dialog>
            
                                  <button
                                    pButton
                                    type="button"
                                    (click)="Instrucciones(rowData)"
                                    pButton
                                    class="btn-in-table outline-color"
                                    icon="pi pi-external-link"
                                    label="Instrucciones"
                                  ></button>
                                  <p-dialog
                                    header="Instrucciones"
                                    [(visible)]="instruccion"
                                    [modal]="true"
                                    [style]="{ width: '50vw' }"
                                    [maximizable]="true"
                                    [baseZIndex]="10000"
                                    [draggable]="false"
                                    [resizable]="false"
                                  >
                                    {{ ins }}
                                  </p-dialog>
            
                                  <!--Si el estatus es Atendido, mostrar el botón-->
                                <ng-container *ngIf="apiService.BuscarPermiso('/webresources/documentofisico','post')">
                                  <button
                                    *ngIf="rowData.estatus == 'Respuesta'"
                                    pButton
                                    type="button"
                                    (click)="DocRelacionado(rowData)"
                                    class="btn-in-table outline-color"
                                    label="Responder oficio"
                                  ></button>
                                  </ng-container>
            
                                <ng-container *ngIf="apiService.BuscarPermiso('/webresources/trazainterna','post')"> 
                                  <button
                                    *ngIf="rowData.estatus!='Cerrado'"
                                    pButton
                                    type="button"
                                    [routerLink]="[
                                      '/turnos/formularioER/' + rowData.trazaInternaId
                                    ]"
                                    label="Turnar"
                                    class="btn-in-table main-color"
                                  ></button>
                                </ng-container>     
                                
                                <ng-container *ngIf=" rowData.organizacionIdDestino.organizacionId == 3 ||
                                rowData.organizacionIdDestino.organizacionId == 244 ||
                                rowData.organizacionIdDestino.organizacionId == 247  ||
                                rowData.organizacionIdDestino.organizacionId == 250"> 
               <button pButton type="button" class="btn-in-table outline-color" label="Indicador" 
               routerLink="/documentofisico/indicador/{{rowData.documentoFisicoId.documentoFisicoId}}"></button>
           </ng-container>

  
                          </div>
                        </ng-container>
                        {{ rowData[col.field] }}
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <!-- <div class="card-footer"></div> -->
        </div>
      </div>
    </section>
    